import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Privacy from './pages/Privacy';
class App extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/:page" component={Privacy} />
          <Route path="/" component={Privacy} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
