
import React from 'react';
import string from '../language/string';

class Privacy extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="agreement-content-title">
                    {string.policyTitle}
                </div>
                    
                <div>
                    {string.policyContent.map((value, index) => {
                        let result = [];
                        if (value.title)
                            result.push(
                            <div key={'title_' + index} className="agreement-content-title">
                                {value.title}
                            </div>
                            );
                        if (value.content)
                            result.push(
                            <div key={'content_' + index} className="agreement-content-body">
                                {value.content}
                            </div>
                            );
                        return result;
                    })}
                </div>
            </div>
        )
    }
}

export default Privacy;